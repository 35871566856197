.editor-wrapper {
  height: 100%;
  width: 100%;
  min-height: 100px;
}

.read-only-editor-wrapper {
  background-color: #cccccc;
  height: 100%;
  width: 100%;
  min-height: 100px;
}
