.storage-option-container{
    display: flex;
    justify-content: start;
    margin-left: 10%;
    cursor: pointer;
    
    @media(max-width: 1000px){
        margin-left: 0;
    }

}
.storage-option-wrapper{
    padding: 12px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    text-transform: none;
    color: inherit;
    width: 300px;
    max-width: 100%;
    &:hover{
        background-color: rgba(0, 0, 0, 0.05);
        transition: 0.1s background-color;
    }

    @media(max-width: 1000px){
        margin: auto;
    }

    .so-i-w{
        flex-basis: 50px;
        display: flex;
        align-items: center;
        justify-content: start;

        img{
            max-width: 40px;
        }
    }

    .so-p-n{
        flex-basis: 130px;
    }

    .so-icon{
        height: auto;
        width: 40px;
    }

}