.profile-small{
    cursor: pointer;
    border-radius: 10px;
    padding: 8px 8px 8px 12px;
    transition: 0.1s background;

    &:hover{
        background: var(--fq-hover-color-1);
    }

    .profile-small-name{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

}
.profile-dropper{
    box-shadow: var(--fq-box-shadow-2);
    border-radius: 10px;
    background-color: var(--fq-background-color-1);
    min-width: 200px;
    max-width: 100vw;
    padding-top: 16px;
    text-align: center;
    margin-top: 8px;
    padding-bottom: 4px;
    overflow: hidden;

    .profile-dropper-block{
        padding-left: 16px;
        padding-right: 16px;
    }

    .logout-button{
        width: 100%;

    }
}