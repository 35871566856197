.tab-group-container {
  width: auto;
  height: auto;
  overflow: auto;
  display: flex;

  button {
    outline: none;
    border: none;
    background-color: transparent;
    border-bottom: 3px solid transparent;
    padding: 12px 16px;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: 0.3s;
  }

  .active-tab{
    border-color:  var(--fq-primary-color);
    color: var(--fq-primary-color);
    transition: 0.3s;
  }

  .disabled-tab{
    border-color: transparent;
    color: var(--fq-disabled);
    pointer-events: none;
    cursor: default;
  }
}