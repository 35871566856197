.transfer-method-container{
    display: flex;
    justify-content: start;
    //margin-left: 10%;
    cursor: pointer;
    
    @media(max-width: 1000px){
        margin-left: 0;
    }

}
.transfer-method-wrapper{
    padding: 12px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    text-transform: none;
    color: inherit;
    width: 100%;
    max-width: 100%;
    &:hover{
        background-color: rgba(0, 0, 0, 0.05);
        transition: 0.1s background-color;
    }

    @media(max-width: 1000px){
        margin: auto;
    }

    .tm-i-w{
        flex-basis: 50px;
        display: flex;
        align-items: center;
        justify-content: start;

        img{
            max-width: 40px;
        }
    }

    .tm-p-n{
        //flex-basis: 130px;
        font-size: 1rem;
    }

    .tm-icon{
        height: auto;
        width: 30px;
    }

}