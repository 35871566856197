.about-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (max-width: 800px) {
    padding-top: 24px;
  }

  @media (max-width: 1200px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  .logo-overlay {
    opacity: 0.7;
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    max-width: 100%;
    right: 0;
    transform: translateX(30%);
  }


  .about-main-content {
    width: 500px;
    max-width: 100%;

    @media (max-width: 900px) {
      text-align: center;
      margin: auto auto 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--fq-border-color-1);
    }

  }

  .from-the-developer {
    position: relative;
    max-width: 300px;
    overflow: hidden;
    background: linear-gradient(#fff0, #ffffffd6);

    @media (max-width: 900px) {
      margin: auto;
      width: 400px;
      max-width: 100%;
    }


    .socialmedia-link {
      position: absolute;
      top: 12px;
      right: 20px;
      z-index: 100;
    }

    .profile-image {
      max-width: 80px;
      border-radius: 10px;
      margin-bottom: 8px;
    }

    .profile-image-backdrop {
      position: absolute;
      top: 0;
      z-index: -1;
      opacity: 0.3;
      right: -50%;
    }

  }

  .a-text-content {
    max-width: 500px;

    @media (max-width: 800px) {
      padding: 8px 24px;
      text-align: center;
    }
  }

  .full-height {
    //height: calc(100vh - var(--fq-header-max-height) - 56px);
    flex: 1;
  }
}