.shifter-wrapper{
    max-width: var(--fq-header-max-width);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    flex: 1;
    
    @media (max-width: 1300px) {
      padding: 20px;
    }

    .sf-welcome-container{
      height: calc(100vh - var(--fq-header-max-height) - 56px);
      align-items: center;


      @media (max-width: 900px) {
        height: auto
      }

    }
    .welcome-text-container{
      display: flex;
      flex-direction: column;
      align-items: start;

      @media (max-width: 900px) {
        align-items: center;
        text-align: center;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }
    }
    .sf-er-welcome-text{
      max-width: 500px;
    
    }

    .transition-enter{
      opacity: 0;
      transform: translateX(40%);
    }

    .transition-enter-active {
      opacity: 1;
      transform: translateX(0%);
      transition: opacity 300ms, transform 300ms;
    }
    .transition-exit {
      opacity: 1;
      transform: translateX(0%);
    }
    .transition-exit-active {
      opacity: 0;
      transform: translateX(20%);
      transition: opacity 300ms, transform 300ms;
    }

    .transition-exit-done{
      opacity: 0;
      transform: translateX(20%);
    }
  }