.save-query-container {
  position: relative;
  padding: 0 8px 16px 16px;
  width: 400px;
  overflow: hidden;

  .dialog-action {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -16px;
    margin-right: -8px;
    padding-right: 8px;


    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: var(--fq-z-index-minimum);
      box-shadow: 0 0 2px 1px var(--fq-border-color-1);
    }
  }

  .save-query-body {
    padding-right: 8px;
    margin-top: 24px;
  }
}