.modal-container{
  outline: none !important;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  border: 1px solid var(--fq-border-color-1)
}

/*
.fd-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0.5 !important;
  right: 0;
  transition: 0.5s;
}*/
