.drawer-container-dummy {
  width: var(--drawer-width);
  min-width: var(--drawer-width);

  .drawer-container {
    position: fixed;
    top: 70px;
    width: var(--drawer-width);
    min-width: var(--drawer-width);
    max-width: var(--drawer-width);
    border-right: 1px solid var(--fq-border-color-1);
    height: calc(100vh - 70px);
    padding: 16px 16px 16px;
    transition: 0.3s;
    overflow: auto;
    overflow-x: hidden;

    .search-wrapper {
      position: relative;

      .loading-progress {
        position: absolute;
        top: 0;
        bottom: 8px;
        right: 12px;
        margin: auto;
        z-index: 100;
      }
      input {
        margin-bottom: 8px;
        width: 100%;
        padding: 12px 16px;
        outline: none;
        border: none;
        background: #eaeef3;
        border-radius: 10px;
        position: sticky;
        z-index: var(--fq-z-index-maximum);
        top: 0;
      }
    }
  }
}

.drawer-container * {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.drawer-expanded {
  --drawer-width: 300px;
}

.drawer-collapsed {
  --drawer-width: 100px;
}
