@import 'codemirror/lib/codemirror.css';

.CodeMirror {
    --font-size: 0.8425rem;
    height: 100% !important;
    font-size: var(--font-size);
    font-family: var(--fq-mono-font-family) !important;
    line-height: calc(var(--font-size) * 2);
    font-weight: 600;
    //color: #545454 !important;
    color: var(--fq-monospace-primary-font-color) !important;

    .CodeMirror-linenumber {
        background: transparent;

        color: #c3c3c3 !important;
    }

    .CodeMirror-gutters {
        background: transparent;
        border-right: none;
    }

    .CodeMirror-line {
        padding-left: 24px !important;
    }

    .CodeMirror-line-like {
        padding-left: 16px !important;
        font-family: var(--fq-font-family);
    }

    .CodeMirror-selected {
        background: rgba(0, 0, 0, 0.1) !important;
    }

    .cm-keyword {
        color: #bb3ece !important;
    }

    .cm-string {
        color: #22a32c !important;
    }

    .cm-number {
        color: #cb8926 !important;
    }

    .cm-comment {
        color: #b6b8c3 !important;
    }

    .cm-atom {
        color: #00a1e0 !important;
    }

    .cm-builtin {
        color: #434bce;
    }
}

.CodeMirror-hints {
    --fq-cm-primary-color: hsl(var(--fq-primary-hue), var(--fq-primary-saturation), var(--fq-primary-luminance), 0.9);
    position: absolute;
    z-index: var(--fq-z-index-maximum);
    overflow: hidden;
    list-style: none;
    margin: 0;
    -webkit-box-shadow: var(--fq-box-shadow-2);
    -moz-box-shadow: var(--fq-box-shadow-2);
    box-shadow: var(--fq-box-shadow-2);
    background: white;
    font-size: 0.85rem;
    max-height: 20em;
    overflow-y: auto;
    border-radius: 4px;
    font-family: var(--fq-mono-font-family);
    min-width: 250px;
    font-weight: 500;

    .CodeMirror-hint {
        margin: 0;
        padding: 6px 8px;
        white-space: pre;
        color: black;
        cursor: pointer;
    }

    li.CodeMirror-hint-active {
        --fq-primary-alpha: 0.2;
        color: #ffffff;
        background: var(--fq-cm-primary-color);
    }
}
