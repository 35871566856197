.PaymentWrapper{
  position: relative;
  width: fit-content;

  .PaymentWrapper-razorpay-button{
    opacity: 0;
    z-index: 100;
    position: relative;
  }

  .PaymentWrapper-custom-button{
    position: absolute;
    background: var(--fq-secondary-color);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #ffffff;
    font-size: 16px;
  }
}