.object-list-container {
	position: relative;

	.object-list-body {
		margin-top: 24px;
		overflow: auto;
		max-height: calc(100vh - 385px);
		//padding: 0 8px;
	}
}

.OL-filter-options {
	box-shadow: var(--fq-box-shadow-2);
	border-radius: 8px;
	overflow: hidden;
	padding-top: 4px;
	padding-bottom: 4px;
	background-color: #fff;

	li {
		cursor: pointer;
		padding: 10px 12px;
		font-size: 0.8325rem;
		color: var(--fq-secondary-text-color);
		&:hover {
			background-color: var(--fq-border-color-1);
		}
	}
}

.OL-Search {
	display: flex;
	position: sticky;
	top: 0;
	gap: 16px;

	input {
		padding: 12px 16px;
		width: 100%;
		font-size: 0.85rem;
		border-radius: 10px;
		outline: none;
		border: none;
		background-color: #eaeef3;
	}

	.OL-control-button {
		background-color: #eaeef3;
	}
}

.OL-list-selected {
	background-color: var(--fq-primary-color);
	color: var(--fq-text-color-opposite-1);
	border-radius: 10px;
	&:hover {
		background-color: var(--fq-primary-color) !important;
	}
}

.OL-list {
	width: 100%;
	display: flex;
	gap: 16px;
	align-items: center;
	padding: 8px 8px;
	cursor: pointer;
	//border-bottom: 1px solid var(--fq-border-color-1);
	position: relative;
	&::after {
		position: absolute;
		bottom: 0;
		left: 6px;
		right: 6px;
		content: '';
		height: 1px;
		background-color: var(--fq-border-color-1);
	}
	&:hover {
		transition: background-color 100ms;
		border-radius: 10px;
		//transform: scale(1.02);
		transition: 0.3s;
		background-color: var(--fq-border-color-1);
		// &::after {
		// 	background-color: #000;
		// }
	}

	> * {
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
	.OL-l-label {
		font-size: 0.87rem;
		line-height: 1.57;
	}
}
