.SkeletonContainer{
  background: transparent;
  position: relative;

  &:after{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    background: linear-gradient(rgba(255, 0, 0, 0), #fff);
    left: 0;
    right: 0;
  }
}