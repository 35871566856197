.width-max-adjust {
    max-width: var(--fq-header-max-width);
    @media (min-width: 1000px) {
        margin: auto !important;
    }
}

.home-container {
    width: 100%;
    margin: 0em auto 0;
    overflow: hidden;

    @media (max-width: 1199px) {
        margin-top: 2rem;
    }

    .welcome-mockup {
        height: calc(100vh - var(--fq-header-max-height) - 56px);

        @media (max-width: 1199px) {
            height: auto;
        }
    }

    .image-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;

        @media (max-width: 1199px) {
            height: auto;
            margin-top: 1rem;
        }
    }

    img {
        transform: scale(1);
        max-width: 170%;
        border-radius: 10px;
        position: inherit;
        box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 9%);

        @media (max-width: 1400px) {
            max-width: 140%;
        }

        @media (max-width: 1199px) {
            max-width: 100%;
            margin-top: 24px;
            height: auto;
            margin-bottom: 2rem;
        }
    }

    .screenshot-2 {
        position: absolute;
        left: 30px;
        bottom: -30px;
        z-index: 100;
        max-width: 400px;
    }

    .padding-wrapper {
        padding-left: 16px;
        padding-right: 16px;

        @media (max-width: 1000px) {
            padding-left: 20px;
            padding-right: 20px;
            margin-top: 1.5rem;
        }
    }

    .getting-started {
        width: 180px;
        color: #fff;
        font-size: 16px;
        border-radius: 10px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: var(--fq-primary-color);
        padding: 16px;
        cursor: pointer;

        @media (max-width: 500px) {
            width: 100%;
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .view-demo {
        background: transparent;
        color: var(--fq-primary-color);
        padding: 10px;
        background: #e7e7e7;
        border: 1px solid #cdc4c4;
        color: var(--fq-secondary-text-color);

        &:hover {
            text-decoration: none;
        }

        @media (max-width: 562px) {
            margin-top: 16px;
        }
    }

    .home-workflow {
        width: 100%;
        margin-top: 5em;
        padding: 5em 40px 40px;
        @media (max-width: 600px) {
            padding: 4em 0px 0px;
        }
    }

    .home-features {
        margin-top: 3rem !important;
    }

    .start-using-now {
        border-top: 1px solid rgba(0, 0, 0, 0.09);
        margin-top: 3em;
        padding-top: 5em;
        padding-bottom: 5em;
    }

    .start-now-card {
        width: 100%;
        padding: 24px;
        height: fit-content;

        &:hover {
            box-shadow: rgba(90, 105, 120, 0.1) 1px 1px 40px 5px;
            transition: 0.3s;
        }

        a {
            margin-top: 8px;
            text-decoration: inherit;
            color: var(--fq-primary-color);
            display: flex;
            align-items: center;
        }
    }

    .product-theme {
        // background: radial-gradient(
        //         61.04% 89.69% at 100% 100%,
        //         rgba(200, 250, 255, 0.01) 0%,
        //         rgba(28, 210, 229, 0.01) 40.63%,
        //         rgba(28, 210, 229, 0) 100%
        //     ),
        //     radial-gradient(43.78% 64.34% at 60.31% 100%, rgba(23, 74, 228, 0.01) 0%, rgba(23, 74, 228, 0) 100%),
        //     linear-gradient(180deg, rgba(23, 74, 228, 0) 29.44%, rgba(23, 74, 228, 0.01) 100%),
        //     linear-gradient(90deg, #f3f3f7 0%, #ebf0f9 100%);
        background: white;
        border: none;
    }
}

.home-main-content {
    max-width: 500px;
    position: relative;
    align-items: center;

    @media (max-width: 1199px) {
        margin: auto;
        text-align: center;
    }

    .button-wrapper {
        max-width: 400px;
        justify-content: center;
        margin: 24px auto auto;

        @media (min-width: 800px) {
            max-width: 400px;
            justify-content: start;
            margin: 24px 0 0;
        }
    }
}

.colored-home-section {
    background: #006aff09;
}

.demo-video-player-wrapper {
    height: 619px;
    width: 1100px;
    border-radius: var(--fq-border-radius-1);
    overflow: hidden;

    @media (max-width: 1100px) {
        height: 90vh;
        width: 90vw;
    }

    .demo-video-player-control {
        position: absolute;
        top: 4px;
        right: 2%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: 0.3s;
        transition-delay: 2s;

        .close-button-text {
            color: rgb(238, 238, 238);
            text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px;
            margin: 0 0;
        }

        .player-close-button {
            height: 36px;
            width: 36px;
        }
    }

    &:hover {
        .demo-video-player-control {
            right: 17%;
            transition: 0.3s;
        }
    }
}

.animation-enter {
    opacity: 0;
}
.animation-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.animation-exit {
    opacity: 1;
}
.animation-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}
