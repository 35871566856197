.notepad-home {
    text-align: left !important;

    @media (max-width: 1000px) {
        text-align: center;
    }

    .clip-memo-input {
        outline: none;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 1px 2px #cdc4c4;
        padding: 16px 24px;
        max-width: 99%;
        font-size: 2.5rem;
        color: #132f4c;
        font-family: var(--fq-font-family);
        &::placeholder {
            color: rgba(31, 31, 31, 0.137);
        }

        @media (max-width: 1200px) {
            font-size: 2.5rem;
        }

        @media (max-width: 600px) {
            font-size: 2rem;
        }

        @media (max-width: 500px) {
            width: 100%;
            padding: 13px 24px;
            font-size: 1.2rem;
        }
    }

    .button-wrapper {
    }

    .retrieve-note {
        @media (max-width: 900px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        @media (max-width: 500px) {
            h6 {
                width: 100%;
                text-align: left !important;
            }
        }

        justify-content: flex-start;
        align-items: flex-start;
    }

    .retrieve-button {
        margin-top: 24px;
    }
}
