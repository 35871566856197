.sosl-query-result-container{
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}


.sosl-query-result-modal-action{
    padding: 8px 16px;
    border-bottom: 1px solid var(--fq-border-color-1);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;

    *{
        flex: 1;
    }
}

.sosl-query-result-wrapper{
    .sosl-result-grid-wrapper{
        flex-shrink: 1;
        flex-grow: 1;
    }
}