.MeWrapper {
    padding: 0;
    text-align: left;
    //background-color: #000515;

    p {
        font-size: 1rem !important;
    }

    a {
        text-decoration: none;
    }

    @media (max-width: 1300px) {
        padding: 24px;
        padding-top: 0;
        margin-top: 0;
    }

    @media (max-width: 600px) {
        //text-align: center;
    }

    .me-subheading {
        @media (max-width: 600px) {
            padding-top: 8px;
        }
    }

    .me-action-button {
        justify-content: flex-start;
    }

    .resume-download-button {
        border: 1px solid;
        border-color: #e7e7e7;
        margin-left: -10px;
        background: rgba(255, 255, 255, 0.575);
        margin-top: -48px;
    }
    .me-projects-card {
        // background: radial-gradient(
        //         61.04% 89.69% at 100% 100%,
        //         rgba(200, 250, 255, 0.08) 0%,
        //         rgba(28, 210, 229, 0.08) 40.63%,
        //         rgba(28, 210, 229, 0) 100%
        //     ),
        //     radial-gradient(43.78% 64.34% at 60.31% 100%, rgba(23, 74, 228, 0.08) 0%, rgba(23, 74, 228, 0) 100%),
        //     linear-gradient(180deg, rgba(23, 74, 228, 0) 29.44%, rgba(23, 74, 228, 0.06) 100%),
        //     linear-gradient(90deg, #f3f3f7 0%, #ebf0f9 100%);
    }

    .me-social-media {
        display: block;
        width: 40px;
        position: fixed;
        bottom: 0px;
        left: 40px;
        right: auto;
        z-index: 10;
        color: #006aff;

        @media (max-width: 1450px) {
            display: none;
        }

        ul {
            margin: 20px 0;
        }
        li {
            padding: 6px;
            svg {
                width: 100%;
                margin: auto;
            }
        }
        &::after {
            content: '';
            display: block;
            width: 1px;
            height: 90px;
            margin: 0px auto;
            background-color: #006aff;
        }

        &::before {
            content: '';
            display: block;
            width: 1px;
            height: 90px;
            margin: 0px auto;
            background-color: #006aff;
        }
    }

    .me-freelancing {
        width: 40px;
        position: fixed;
        //bottom: 0px;
        top: 70px;
        left: auto;
        right: 40px;
        z-index: 10;

        div {
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
            position: relative;

            a {
                margin: 20px auto;
                padding: 10px;
                font-family: var(--font-mono);
                font-size: var(--fz-xxs);
                line-height: var(--fz-lg);
                letter-spacing: 0.1em;
                -webkit-writing-mode: vertical-rl;
                writing-mode: vertical-rl;
            }
        }

        &::before {
            content: '';
            display: block;
            width: 1px;
            height: 90px;
            margin: 0px auto;
            background-color: #006aff;
        }
    }
}
