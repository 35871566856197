.ShifterMethodWrapper{
  width: 100%;
  height: 100%;
  flex: 1;

  .SM-Wrapper{
    max-width: var(--fq-header-max-width);
    margin: 0 auto;

    
    @media (max-width: 1300px) {
      padding: 20px;
    }
  }

  .padding-fix-override{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .back-navigation{
    text-decoration: none;
    color: inherit;
    display: block;
    width: fit-content;
    margin: 6px 0;
    padding: 6px 0;
    padding-right: 8px;
    margin-left: -4px;

    &:hover{
      background-color: rgb(0, 0, 0, 0.1);
      border-radius: 8px;
    }
  }
}