.saved-query {
  ul {
    padding-left: 18px;
    margin: 8px 0 8px 8px;

    li {
      padding: 6px 0;
      letter-spacing: 1px;
      cursor: grab;

      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .soql-logo {
    background: rgba(255, 0, 30, 0.1);
    border-radius: 4px;
    color: red;
    min-width: 35px;
    max-width: 35px;

    p {
      width: 100%;
      margin: auto;
      font-size: 8px;
      padding: 4px 5px;
      font-weight: 500;
    }
  }

  .sosl-logo{
    background: rgba(153, 0, 255, 0.1);
    color: #9900ff;
  }
}
