.visual-query-composer-container {
  height: auto;
  padding: 4px 0;
  overflow: hidden;
  width: 50%;
  max-width: 350px;

  .visual-query-selector {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 1rem;
    padding-left: 2px;
  }

  .fq-autocomplete-input {
    border-radius: var(--fq-border-radius-1) !important;
  }

  .order-control-wrapper {
    margin-top: 12px;
  }

  .order-block-item {
    flex: 1;

    > div {
      width: 100%;
    }
  }

  .order-block-select-1 {
    //width: 125px;
    border-radius: 10px;
    height: 40px;
    width: 50%;
  }

  .order-block-select-2 {
    border-radius: 10px;
    height: 40px;
    width: 50%;
    //width: 100px;
  }

  .order-icon-add {
    margin-top: 24px;
    flex: 1;
  }
}

.MuiPopover-paper {
  box-shadow: var(--fq-box-shadow-2) !important;
  border-radius: 10px !important;
}

.ConditionBlock-wrapper {
  padding: 0 0 16px;
  max-height: 50%;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--fq-border-color-1);
  width: 100%;
  position: static;
  overflow: auto;
  flex: 1;
  //max-height: 50%;

  .MuiAutocomplete-tag {
    margin: 0 2px !important;
  }

  .cw-wrapper {
    display: grid;
    gap: 32px;
    margin-bottom: 4px;
  }

  .cw-condition-block {
    display: flex;
    gap: 8px;
    padding: 8px 0;
    margin: 0 16px;
    flex: 1;
    border-radius: var(--fq-border-radius-1);
    align-items: center;
    position: static;
  }

  .cw-condition-title {
    box-sizing: border-box;
    position: sticky;
    top: 0;
    padding: 4px 0 !important;
    z-index: var(--fq-z-index-medium);
    margin-bottom: 0;
  }

  .cw-field {
    width: 300px;
    max-width: 40%;
    position: static;
  }

  .cw-operator {
    width: 200px;
    max-width: 25%;
  }

  .cw-value-selector {
    flex: 1;
  }

  .cw-new-block {
    padding: 0 16px;
  }

  .cw-condition-join-wrapper {
    position: relative;
  }

  .cw-condition-join-operator {
    position: absolute;
    top: -32px;
    left: 16px;
    z-index: var(--fq-z-index-medium);

    .cw-condition-join {
      padding: 3px;
      border: 1px solid var(--fq-border-color-1);
      border-radius: var(--fq-border-radius-1);
      gap: 4px;

      .cw-condition-join-item {
        padding: 2px 4px;
        border-radius: 8px;
        background: #ffffff;
        border: none;
      }

      .Mui-selected {
        background: var(--fq-primary-color);
        color: var(--fq-text-color-opposite-1);
      }
    }
  }
}
