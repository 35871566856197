.future-wrapper{
  width: 100%;


  @media (max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 1.5rem;
  }

  .upcoming-card{
    &:hover{
      box-shadow: var(--fq-box-shadow-2);
    }
  }
}