.feature-card {
    border-radius: 10px;
    transition: 0.3s;
    //background-color: #e9ecef;
    padding: 16px 24px 23px;
    border: 1px solid var(--fq-border-color-1);

    &:hover {
        //box-shadow: 0 0 1px 1px #d5d5d5, rgba(90, 105, 120, 0.2) 1px 1px 20px 0px;;
        box-shadow: var(--fq-box-shadow-2);
        transition: 0.3s;
    }
}
