.query-window-selector-wrapper {
  position: relative;
  //margin-bottom: -18px;

  .query-window-container {
    padding: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    z-index: var(--fq-z-index-maximum);

    .qw-item-wrapper {
      gap: 4px;
      border-radius: 0;

      .qw-item {
        border-radius: 0;
        border: none;
        padding: 8px 8px;
        margin: 0 0 -10px;
      }

      .Mui-selected {
        //background: var(--fq-primary-color);
        //color: var(--fq-text-color-opposite-1);
        border-bottom: 2px solid var(--fq-primary-color);
        background: transparent;
      }
    }

  }
}