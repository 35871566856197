footer {
    background: var(--fq-primary-background);
    z-index: 1000;

    .footer-company-logo {
        width: 24px;
    }

    .small-footer {
        margin-top: 16px;

        @media (max-width: 1200px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .footer-container {
        margin-bottom: 0;
        padding: 4em 0 2.5em;

        @media (max-width: 1200px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (max-width: 500px) {
            margin-bottom: 1em;
            padding: 2em 20px;
        }

        .link {
            text-decoration: none;
            color: inherit;

            &:hover {
                text-decoration: underline;
                color: var(--fq-primary-color);
            }
        }
        .news-letter {
            margin-top: 24px;
            .news-letter-action {
                display: flex;
                margin-top: 8px;

                input {
                    outline: none;
                    border: none;
                    background: rgba(0, 0, 0, 0.09);
                    padding: 12px;
                    border-radius: 10px 0 0 10px;
                    font-size: 14px;
                    min-width: 280px;
                    &::placeholder {
                        color: rgba(0, 0, 0, 0.27);
                    }

                    @media (max-width: 400px) {
                        min-width: 220px;
                    }
                }

                button {
                    outline: none;
                    border: none;
                    background: rgba(2, 2, 2, 0.14);
                    padding: 12px;
                    border-radius: 0 10px 10px 0;
                    cursor: pointer;
                }
            }
        }
    }
}
