.query-operation-selector {
  position: absolute;
  bottom: 12px;
  padding: 2px 8px 2px 0;
  right: 24px;
  border: 1px solid var(--fq-border-color-1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: #eff1f5;
  z-index: var(--fq-z-index-maximum);
}