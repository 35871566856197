/// export UI
.SM-Container {
	max-width: var(--fq-header-max-width);
	margin: 0 auto;
}

.sl-e-w {
	.stepper-position-adj {
		position: absolute;
		bottom: 66px;
		right: 0;
		left: 0;
	}
	.stepper-width {
		//max-width: 1018px;
	}
}

.bottom-footer-navigation {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	padding: 16px 0;
	///max-width: 1000px;
	margin: auto;
}

.Ex-selector-body {
	//max-width: 800px;
	margin: auto;
}

.sl-e-w {
	margin-top: 24px;
	padding: 0 8px;

	.sl-main-text {
		margin-bottom: 4px;
		font-weight: 600;
		font-size: clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem);
	}
}

/// object list
