.TermsOfService{
  max-width: var(--fq-header-max-width);
  margin: auto;
  width: 100%;

  @media (max-width: 1300px) {
    padding: 20px;
  }

  .terms-container{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  ul{
    margin-top: 8px;
    list-style-type: disc;
    padding-left: 16px;
  }
}