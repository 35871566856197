.dashboard-container {
  max-width: 100vw;
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  display: flex;
  overflow: auto;
  transition: 0.3s;

  .dashboard-content {
    height: 100%;
    overflow: auto;
    flex-grow: 1;
  }

  .query-box {
    height: calc(100vh - 70px);
    overflow: auto;
    width: 100%;

    .query-editor-controls {
      display: flex;
      flex-direction: column;
      height: 50%;
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  .dashboard-editor-container {
    height: 100%;
    overflow: auto;
    //position: relative;

    .dashboard-editor-visual-container {
      display: flex;
      padding: 8px 0 8px 16px;
      height: 100%;

      .dashboard-query-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        //position: relative;

        .dashboard-query-editor-container {
          //flex: 1;
          overflow: auto;
          position: relative;
        }
      }
    }
  }

  .data-grid-wrapper {
    border-top: 1px solid var(--fq-border-color-1);
    overflow: auto;
    height: 50%;
  }
}

.gutter {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: 0.3s;
  border: 1px dashed transparent;

  &:hover {
    border-color: var(--fq-border-color-1);
  }
}

.gutter.gutter-vertical {
  height: 10px;
  position: relative;
  cursor: row-resize;
}
