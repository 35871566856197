.query-tab-wrapper {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid var(--fq-border-color-1);
  width: 100%;
  padding: 5.5px 16px;

  .query-tab-container{
    display: flex;
    gap: 8px;
    flex: 1;
    align-items: center;
  }

  .query-button {
    border-radius: 10px;
    border: 1px solid var(--fq-border-color-1);
  }

  ul > div {
    display: flex;
    align-items: center;
    gap: 8px;

    li {
      padding: 0 2px 0 10px;
      cursor: pointer;
      min-height: 34px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 0.9rem;
      text-transform: uppercase;

      &:hover {
        background: var(--fq-hover-color-1);
        transition: 0.3s;
      }

      .MuiBadge-dot{
        background-color: rgb(153, 0, 255) !important;
      }

    }

    .fq-tab-active {
      background: #000;
      color: #fff;

      &:hover{
        background: #000;
        color: #fff;
      }
    }
  }
}

