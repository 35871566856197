ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;


  li {
    padding: 2px 0;
  }

  ul {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    margin: 8px 8px 0 16px;
    padding-left: 22px;

    li {
      cursor: grab;
      padding: 8px 0;
      letter-spacing: 1px;
    }
  }
}