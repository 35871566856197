.blink_me {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.3;
    }
}

.NotePadContent-Wrapper {
    .notedrop-editor-container {
        max-height: -webkit-fill-available;
    }
    .notepad-editor {
        @media (max-width: 500px) {
            max-width: 98vw;
        }
    }

    .notepad-editor-divider {
        @media (max-width: 500px) {
            display: none;
        }
    }

    .notepad-sidenav-small {
        display: none;

        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;

        @media (max-width: 700px) {
            display: flex;
            background: #fff;
            z-index: 1500;
            border-top: 1px solid var(--fq-border-color-1);
            box-shadow: 7px 1px 5px 1px var(--fq-border-color-1);
        }
    }

    .notepad-sidenav-large {
        display: none;
        @media (min-width: 701px) {
            display: flex;
        }
    }
}
