header {
  display: flex;
  justify-content: space-between;
  padding: var(--padding);
  align-items: center;
  border-bottom: 1px solid var(--fq-border-color-1);
  position: sticky;
  width: -moz-available;
  width: -webkit-fill-available;
  z-index: 100;
  top: 0;
  background: rgba(255, 255, 255, 0.76);
  backdrop-filter: blur(10px);
  max-height: 70px;
  padding-left: 20px;
  padding-right: 20px;

  .header-wrapper {
    width: 100%;
    max-width: var(--fq-header-max-width);
    margin: auto;
    transition: 0.5s;
    -moz-transition: 0.5s;

    nav {
      display: flex;
      gap: 20px;

      a {
        text-decoration: none;
        padding: 25px 5px;
        font-weight: 500;
        color: var(--fq-primary-text-color);
        transition: 0.3s;

        &:hover, &:focus {
          color: var(--fq-primary-color);
          transition: 0.3s;
        }
      }

      .active {
        color: var(--fq-primary-color);
        transition: 0.3s;
      }
    }
  }
}