.authentication-container {
  background: var(--fq-background-color-5);
  //height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .login-illustration {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
}