.drag-and-drop-container{
    --border-color: var(--fq-border-color-2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-height: 200px;
    flex-direction: column;
    border: 2px dashed var(--border-color);

    img{
        max-width: 100px;
    }

    .drag-and-drop-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    }
}